import React, { Component, Fragment } from 'react';
import { Popover, Button, InputNumber, message } from 'antd';
import { EditOutlined, SwapOutlined } from '@ant-design/icons';

const TRANSACTION_TYPE = Object.freeze({
  PURCHASE: 'PURCHASE',
  TRANSFER: 'TRANSFER',
});
const COLOR_PRIMARY = '#f1c400';

const TransactionEditInput = ({
  record,
  handleChange,
  clickPurchase,
  editInputValue,
  toggleVisible,
  transfer,
}) => (
  <div>
    {transfer || record.type === TRANSACTION_TYPE.TRANSFER ? (
      <>
        <div>
          <EditOutlined style={{ color: COLOR_PRIMARY, marginRight: '10px' }} />
          Please input the number of subscription keys
          <br />
          you would like to transfer to this sub-brokerage.
        </div>
        <InputNumber
          min={0}
          style={{ marginTop: '15px' }}
          defaultValue={0}
          onChange={handleChange}
        />
      </>
    ) : (
      <>
        <div>
          <EditOutlined style={{ color: COLOR_PRIMARY, marginRight: '10px' }} />
          Please input the number of subscription keys
          <br />
          you would like to purchase.
        </div>
        <InputNumber
          min={0}
          style={{ marginTop: '15px' }}
          defaultValue={0}
          onChange={handleChange}
        />
      </>
    )}

    <div>
      <div className="purchase-confirm-button-container">
        <Button onClick={() => toggleVisible(false)}>Cancel</Button>
        {transfer ? (
          <Button
            className="purchase-button"
            type="primary"
            onClick={() => clickPurchase(record, editInputValue)}
          >
            Transfer
          </Button>
        ) : (
          <Button
            className="purchase-button"
            type="primary"
            onClick={() => clickPurchase(record, editInputValue)}
          >
            Change
          </Button>
        )}
      </div>
    </div>
  </div>
);

export default class ControlledPopover extends Component {
  state = { visible: false, editInputValue: 0 };

  handleChange = (value) => {
    this.setState({
      editInputValue: value,
    });
  };

  toggleVisible = (visible) => {
    this.setState({ visible });
  };

  clickPurchase = (record, editInputValue) => {
    if (editInputValue === 0) {
      message.warning('Please input a number above 0.', 2);
    } else if (this.props.transfer) {
      this.props.handleTransfer(
        record.brokerageId,
        editInputValue,
        record.name,
      );
      this.toggleVisible(false);
    } else {
      this.props.handleEditTransaction(record.transactionId, editInputValue);
      this.toggleVisible(false);
    }
  };

  render() {
    const { visible, editInputValue } = this.state;
    const { record, transfer } = this.props;
    return (
      <Popover
        {...this.props}
        visible={visible}
        content={
          <TransactionEditInput
            transfer={transfer}
            record={record}
            handleChange={this.handleChange}
            clickPurchase={this.clickPurchase}
            editInputValue={editInputValue}
            toggleVisible={this.toggleVisible}
          />
        }
      >
        {transfer ? (
          <SwapOutlined onClick={() => this.toggleVisible(!visible)} />
        ) : (
          <EditOutlined
            style={{ cursor: 'pointer', color: COLOR_PRIMARY }}
            onClick={() => this.toggleVisible(!visible)}
          />
        )}
      </Popover>
    );
  }
}
