import axios from 'axios';
import { setRequestFeedback } from '../../components/common/app/AppAction';

// APIs
const API_BASE = process.env.REACT_APP_API_BASE;
// errors
const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';
// action types
export const SINGLE_BROKERAGE_INFO = 'detailedBrokerage/SINGLE_BROKERAGE_INFO';
export const BROKERAGE_DETAILED_REQUEST =
  'detailedBrokerage/BROKERAGE_DETAILED_REQUEST';
export const BROKERAGE_DETAILED_INFO =
  'detailedBrokerage/ BROKERAGE_DETAILED_INFO';
export const SET_TABLE_DATA_ERROR = 'detailedBrokerage/ SET_TABLE_DATA_ERROR';

export const REQUEST_BROKERAGE_SUBSCRIPTIONS =
  'broker/REQUEST_BROKERAGE_SUBSCRIPTIONS';
export const RECEIVE_BROKERAGE_SUBSCRIPTIONS =
  'broker/RECEIVE_BROKERAGE_SUBSCRIPTIONS';

// actions
export const singleBrokerageInfo = singleBrokerageInfo => ({
  type: SINGLE_BROKERAGE_INFO,
  singleBrokerageInfo,
});

export const brokerageDetailRequest = brokerageDetailRequest => ({
  type: BROKERAGE_DETAILED_REQUEST,
  brokerageDetailRequest,
});

export const brokerageDetailedInfo = brokerageDetailedInfo => ({
  type: BROKERAGE_DETAILED_INFO,
  brokerageDetailedInfo,
});

export const setTableDataError = tableDataError => ({
  type: SET_TABLE_DATA_ERROR,
  tableDataError,
});

const requestBrokerageSubscriptions = () => ({
  type: REQUEST_BROKERAGE_SUBSCRIPTIONS,
});
const receiveBrokerageSubscriptions = (brokerageId, data) => ({
  type: RECEIVE_BROKERAGE_SUBSCRIPTIONS,
  brokerageId,
  brokerageSubscriptions: data,
});

const fetchBroker = id => async () => {
  const API_BROKERS = `brokerages/${id}/brokers`;
  const url = `${API_BASE}${API_BROKERS}`;
  return axios.get(url);
};

const fetchSubBrokerage = id => async () => {
  const API_BROKERAGES = `brokerages/${id}`;
  const url = `${API_BASE}${API_BROKERAGES}`;
  return axios.get(url);
};

const fetchTransaction = id => async () => {
  const API_TRANSACTIONS = `brokerages/${id}/transactions`;
  const url = `${API_BASE}${API_TRANSACTIONS}`;
  return axios.get(url);
};

export const loadSingleBrokerage = id => async dispatch => {
  dispatch(setRequestFeedback(''));
  const url = `${API_BASE}brokerages/${id}`;
  try {
    const res = await axios.get(url);
    dispatch(singleBrokerageInfo(res.data));
  } catch (err) {
    dispatch(setRequestFeedback(MSG_ERROR));
  }
};

export const fetchBrokerageSubscriptions = brokerageId => async dispatch => {
  dispatch(requestBrokerageSubscriptions());
  const url = `${API_BASE}brokerages/${brokerageId}/subscriptions`;
  try {
    const res = await axios.get(url);
    dispatch(receiveBrokerageSubscriptions(brokerageId, res.data));
  } catch (err) {
    dispatch(receiveBrokerageSubscriptions());
  }
};

export const loadBrokerageDetailedInfo = id => async dispatch => {
  const initialData = { brokers: [], subBrokerages: [], transactions: [] };
  dispatch(brokerageDetailedInfo(initialData));
  dispatch(brokerageDetailRequest(true));
  dispatch(setTableDataError(''));
  try {
    let res = await dispatch(fetchBroker(id));
    const brokers = res.data;
    res = await dispatch(fetchSubBrokerage(id));
    const { subBrokerages } = res.data;
    res = await dispatch(fetchTransaction(id));
    const transactions = res.data;
    const allData = { brokers, subBrokerages, transactions };
    dispatch(brokerageDetailedInfo(allData));
    dispatch(brokerageDetailRequest(false));
  } catch (err) {
    dispatch(brokerageDetailRequest(false));
    dispatch(setTableDataError(MSG_ERROR));
    dispatch(brokerageDetailedInfo(initialData));
  }
};

export const changeQuota = (id, data) => async () => {
  const url = `${API_BASE}brokerages/${id}/purchase`;
  return axios.post(url, data);
};

export const transferQuota = (id, data) => async () => {
  const url = `${API_BASE}brokerages/${id}/transfer`;
  return axios.post(url, data);
};

export const editTransaction = (id, data) => async () => {
  const url = `${API_BASE}transactions/${id}`;
  return axios.patch(url, data);
};

export const changeName = (id, data) => async () => {
  const url = `${API_BASE}brokerages/${id}`;
  return axios.patch(url, data);
};

export const deleteTableContent = (contentId, tab) => async () => {
  const url =
    tab === 'brokers'
      ? `${API_BASE}brokers/${contentId}`
      : `${API_BASE}brokerages/${contentId}`;
  return axios.delete(url);
};

export const addBroker = (values, id) => async () => {
  const url = `${API_BASE}brokerages/${id}/brokers`;
  return axios.post(url, values);
};

export const addSubBrokerage = values => async () => {
  const url = `${API_BASE}brokerages`;
  return axios.post(url, values);
};
