import React, { Component, Fragment } from 'react';
import { Modal, Button, InputNumber, Input, Row, Col } from 'antd';
import { Form } from '@ant-design/compatible';
import './AddBrokerageModal.scss';

import { LOCALSTORAGE_KEY_NAME } from '../../../../constants';

const colorGrey = 'rgba(0,0,0,.55)';
const colorBlack = '#000';

class AddBrokerageModal extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const adminUser = localStorage.getItem(LOCALSTORAGE_KEY_NAME);
        const dataSubBrokerage = {};
        dataSubBrokerage.quota = {
          transferred: values.transfer,
          purchased: values.purchase,
        };
        dataSubBrokerage.name = values.name;
        dataSubBrokerage.createBy = adminUser;

        const dataBrokerage = {};
        dataBrokerage.createBy = adminUser;
        dataBrokerage.quota = { transferred: 0, purchased: values.quota };
        dataBrokerage.name = values.name;
        if (this.props.addBrokerage) {
          this.props.addBrokerage(dataBrokerage);
        } else {
          this.props.addSubBrokerage(dataSubBrokerage);
        }
        this.props.showModal(false);
        this.props.form.resetFields();
      }
    });
  };

  render() {
    const { visible, showModal, brokerageAdding, addBrokerage } = this.props;
    const { getFieldDecorator } = this.props.form;
    const Title = ({ text }) => (
      <span style={{ color: colorGrey }}>{text}</span>
    );
    return (
      <Modal
        closable
        maskClosable
        title="Add Brokerage"
        open={visible}
        footer={null}
        onCancel={() => showModal(false)}
        className="add-brokerage-modal"
      >
        <Form onSubmit={this.onSubmit}>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Name" colon={false}>
                {getFieldDecorator('name', {
                  validateTrigger: 'onBlur',
                  rules: [
                    {
                      required: true,
                      message: 'Please input brokerage name.',
                    },
                  ],
                })(<Input size="large" placeholder="Required Field" />)}
              </Form.Item>
            </Col>
            {addBrokerage ? (
              <Col span={24}>
                <Form.Item label="Subscription Key(s)" colon={false}>
                  {getFieldDecorator('quota', {
                    initialValue: 0,
                  })(<InputNumber min={0} size="large" />)}
                </Form.Item>
              </Col>
            ) : (
              <>
                <Col span={24} style={{ color: colorBlack, fontWeight: '500' }}>
                  Subscription Key(s)
                </Col>
                <Col span={8}>
                  <Form.Item label={<Title text="Transfer" />} colon={false}>
                    {getFieldDecorator('transfer', {
                      initialValue: 0,
                    })(<InputNumber min={0} size="large" />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={<Title text="Purchase" />} colon={false}>
                    {getFieldDecorator('purchase', {
                      initialValue: 0,
                    })(<InputNumber min={0} size="large" />)}
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={24}>
              <Form.Item className="add-button-container">
                <Button type="primary" htmlType="submit">
                  {brokerageAdding ? 'Adding' : 'Add'}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const WrappedAddBrokerageModal = Form.create()(AddBrokerageModal);

export default WrappedAddBrokerageModal;
