import { Component } from 'react';
import { connect } from 'react-redux';
import { initialize } from './AppAction';

import { LOCALSTORAGE_KEY_EXPIRY } from '../../../constants';

import 'antd/dist/reset.css';

class App extends Component {
  componentDidMount() {
    this.props.initialize(
      localStorage.getItem(LOCALSTORAGE_KEY_EXPIRY),
      this.props,
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  const { loginReducer } = state;
  return {
    ...loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  initialize: (expiry, history) => dispatch(initialize(expiry, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
