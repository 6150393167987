/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Input, Row, Col, Spin, message, Pagination } from 'antd';
import {
  CloseCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import Layout from '../../components/layout/Layout';
import RecursiveCollapsedCard from './components/brokerageCard/RecursiveCollapsedCard';
import WrappedAddBrokerageModal from './components/addBrokerageModal/AddBrokerageModal';
import { loadBrokerage, addBrokerage } from './BrokerageAction';
import './brokerages.scss';

const pageSize = 10;
const startItemIndex = 0;
const initialPage = 1;

class Brokerages extends Component {
  searchContentRef = React.createRef();

  state = {
    visible: false,
    searchContent: '',
    start: startItemIndex,
    end: pageSize,
    current: initialPage,
    showContent: [],
    groupedBrokerageList: {},
    groupNull: [],
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.brokerageInfo !== this.props.brokerageInfo) {
      const groupedBrokerageList = _.groupBy(
        this.props.brokerageInfo,
        (brokerage) => brokerage.parentBrokerageId,
      );
      const groupNull = groupedBrokerageList.null || [];
      this.setState({
        groupNull,
        groupedBrokerageList,
        showContent: groupNull,
      });
    }
  }

  loadData = async () => {
    await this.props.loadBrokerage();
    const groupedBrokerageList = _.groupBy(
      this.props.brokerageInfo,
      (brokerage) => brokerage.parentBrokerageId,
    );
    const groupNull = groupedBrokerageList.null || [];
    this.setState({
      groupNull,
      groupedBrokerageList,
      showContent: groupNull,
    });
  };

  addBrokerage = async (values) => {
    await this.props.addBrokerage(values);
    const messages = this.props.requestFeedback;
    if (messages.split(' ')[0] === 'Successfully') {
      message.success(this.props.requestFeedback, 3);
    } else {
      message.error(this.props.requestFeedback, 3);
    }
    await this.loadData();
  };

  showModal = (visible) => {
    this.setState({
      visible,
    });
  };

  handlePageChange = (page, pageSize) => {
    this.setState({
      start: (page - 1) * pageSize,
      end: page * pageSize,
      current: page,
    });
    window.scrollTo(0, 0);
  };

  emitEmpty = () => {
    const { groupNull } = this.state;
    this.searchContentRef.current.focus();
    this.setState({
      searchContent: '',
      showContent: groupNull,
      start: startItemIndex,
      end: pageSize,
      current: initialPage,
    });
  };

  onBrokerageSearchChange = (e) => {
    const { groupNull } = this.state;
    const query = e.target.value;
    this.setState({
      searchContent: query,
    });
    if (query === '') {
      this.setState({
        showContent: groupNull,
        start: 0,
        end: pageSize,
        current: 1,
      });
    } else {
      const queryValue = e.target.value.replace(/\W|_/g, '[$&]');
      const regex = new RegExp(queryValue, 'i');
      const brokeragesData = this.props.brokerageInfo;
      this.setState({
        showContent: brokeragesData.filter((obj) => obj.name.match(regex)),
        start: 0,
        end: pageSize,
        current: 1,
      });
    }
  };

  render() {
    const {
      visible,
      searchContent,
      start,
      end,
      current,
      showContent,
      groupedBrokerageList,
    } = this.state;
    const { brokerageRequest, brokerageAddRequest, brokerageInfo } = this.props;
    const afterPageList = showContent.slice(start, end);
    const suffix = searchContent ? (
      <CloseCircleOutlined key="search-suffix" onClick={this.emitEmpty} />
    ) : null;
    const prefix = <SearchOutlined key="search-prefix" />;
    const totalBrokerage = brokerageInfo.length;

    return (
      <Layout>
        <Row type="flex" justify="center" className="brokerage">
          <Col xs={22} sm={22} md={20} lg={20} xl={16} xxl={15}>
            <div className="brokerage-title-container">
              <h2 className="brokerage-title">BROKERAGES</h2>
              <p className="total-brokerage">{`Total: ${totalBrokerage}`} </p>
            </div>
            <div className="search-container">
              <Button
                className="search-container-button"
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => this.showModal(true)}
              >
                Add
              </Button>
              <Input
                value={searchContent}
                spellCheck={false}
                onChange={this.onBrokerageSearchChange}
                size="large"
                className="search-container-search"
                placeholder="Search..."
                ref={this.searchContentRef}
                prefix={prefix}
                suffix={suffix}
              />
            </div>
            {brokerageRequest ? (
              <div className="loading-spin-container">
                <Spin tip="Loading Brokerages..." size="large" />
              </div>
            ) : (
              <RecursiveCollapsedCard
                showContent={afterPageList}
                allBrokerage={groupedBrokerageList}
              />
            )}
            <div className="pagination-container">
              <Pagination
                current={current}
                defaultCurrent={1}
                total={showContent.length}
                pageSize={pageSize}
                onChange={this.handlePageChange}
                hideOnSinglePage
              />
            </div>
          </Col>
        </Row>
        <WrappedAddBrokerageModal
          visible={visible}
          showModal={this.showModal}
          brokerageAdding={brokerageAddRequest}
          addBrokerage={this.addBrokerage}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { appReducer, brokerageReducer } = state;
  return {
    ...brokerageReducer,
    requestFeedback: appReducer.requestFeedback,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadBrokerage: () => dispatch(loadBrokerage()),
  addBrokerage: (data) => dispatch(addBrokerage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Brokerages);
