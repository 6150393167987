import React, { Component } from 'react';
import { InputNumber, Input, Popconfirm, Popover, Button } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import '../detailedBrokerage.scss';
import ProgressChart from '../../../components/common/ProgressChart';

const primaryColor = '#f1c400';

export default class BrokerageInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      nameValue: { name: props.brokerageInfo.name },
    };
  }

  handleChange = (value) => {
    this.setState({
      value,
    });
  };

  handleNameChange = (e) => {
    this.setState({
      nameValue: { name: e.target.value },
    });
    this.props.showSave();
  };

  render() {
    const {
      brokerageInfo,
      handleSave,
      handleNameSave,
      showNameSaveButton,
      deleteBrokerage,
      purchasePopover,
      handleVisibleChange,
    } = this.props;
    const { value, nameValue } = this.state;
    const { name, quota } = brokerageInfo;
    const text = 'Are you sure to delete this brokerage?';
    const hoverDeleteContent = (
      <p style={{ marginBottom: '0px' }}>
        <ExclamationCircleOutlined style={{ color: primaryColor }} />
        &nbsp;&nbsp;Remove
      </p>
    );

    const PurchaseInput = (
      <div>
        <div>
          <i
            className="fa fa-shopping-cart"
            aria-hidden="true"
            style={{ color: primaryColor, marginRight: '10px' }}
          />
          Please input the number of subscription keys <br />
          you want to purchase.
        </div>
        <InputNumber
          min={1}
          style={{ marginTop: '15px' }}
          defaultValue={1}
          onChange={this.handleChange}
        />
        <div>
          <div className="purchase-confirm-button-container">
            <Button onClick={() => handleVisibleChange(false)}>Cancel</Button>
            <Button
              className="purchase-button"
              type="primary"
              onClick={() => handleSave(value)}
            >
              Purchase
            </Button>
          </div>
        </div>
      </div>
    );
    return (
      <div className="info-container">
        <div className="brokerage-info-container">
          <div className="name-container">
            <Input
              value={this.props.brokerageInfo.name}
              spellCheck={false}
              onChange={(e) => this.handleNameChange(e)}
            />
            {showNameSaveButton ? (
              <div
                className="save-label"
                onClick={() => handleNameSave(nameValue)}
              >
                Save
              </div>
            ) : (
              <Popover
                trigger="hover"
                content={hoverDeleteContent}
                placement="bottomLeft"
                style={{ width: 300 }}
              >
                <Popconfirm
                  placement="bottomLeft"
                  title={text}
                  onConfirm={() => deleteBrokerage(name)}
                  okText="Ok"
                  cancelText="Cancel"
                >
                  <DeleteOutlined className="delete-icon" />
                </Popconfirm>
              </Popover>
            )}
          </div>
          <div className="purchase-popover-container">
            <Popover
              visible={purchasePopover}
              onVisibleChange={handleVisibleChange}
              placement="right"
              trigger="click"
              content={PurchaseInput}
            >
              <div className="purchase-section-container">
                <Button className="shopping-cart-icon">
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                  Purchase
                </Button>
              </div>
            </Popover>
          </div>
        </div>
        <ProgressChart quota={quota} />
      </div>
    );
  }
}
