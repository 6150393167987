import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Popover, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchBrokerageSubscriptions } from '../../../detailedBrokerage/detailedBrokerageAction';
import { brokerageSummarySelector } from '../../../detailedBrokerage/detailedBrokerageSelector';
import './BrokerageCard.scss';

const COLOR_PROGRESS = '#F1C400';

class BrokerageCard extends Component {
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { brokerage } = this.props;
    const { brokerageId } = brokerage;
    try {
      await this.props.fetchBrokerageSubscriptions(brokerageId);
    } catch (err) {
      message.error(err);
    }
  };

  render() {
    const { brokerage, subBrokerage } = this.props;
    const { name, brokerageId, numOfBrokers, summary } = brokerage;

    const quota = summary || {
      expiredPromoCodes: 0,
      expiredSubscriptions: 0,
      promoCodes: 0,
      subscriptions: 0,
      total: 0,
    };
    const content = (
      <p style={{ marginBottom: '0px' }}>
        <ExclamationCircleOutlined
          style={{ color: COLOR_PROGRESS, marginRight: '10px' }}
        />
        <strong>{quota.expiredSubscriptions} </strong>
        subscription key(s) expired;{' '}
        <strong>{quota.subscriptions + quota.promoCodes}</strong> subscription
        key(s) used
      </p>
    );
    return (
      <Link to={`/brokerages/${brokerageId}`}>
        <div className="card-container">
          <div className="broker-info">
            <h2 className="brokerage-name">{name}</h2>
            <p className="indicator-info">
              {`Sub Brokerages: ${subBrokerage} / Broker Admins: ${numOfBrokers}`}
            </p>
          </div>
          <Popover content={content} trigger="hover">
            <div className="license-container">
              <h2 className="quota-number">{quota.total}</h2>
              <p className="licenses">Subscription Key(s)</p>
            </div>
          </Popover>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  summary: brokerageSummarySelector(state),
});

const mapDispatchToProps = { fetchBrokerageSubscriptions };

export default connect(mapStateToProps, mapDispatchToProps)(BrokerageCard);
