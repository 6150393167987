/* eslint-disable default-param-last */
import { LOGIN_REQUEST, ERROR_MESSAGE, EXPIRY_DATE } from './LoginAction';

const initialState = {
  loginRequest: false,
  errorMessage: '',
  expiryDate: '',
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loginRequest: action.loginRequest };
    case ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };
    case EXPIRY_DATE:
      return { ...state, expiryDate: action.expiryDate };
    default:
      return state;
  }
};
