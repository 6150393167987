import axios from 'axios';
import * as HttpStatus from 'http-status-codes';
import { setRequestFeedback } from '../../components/common/app/AppAction';

// APIs
const API_BASE = process.env.REACT_APP_API_BASE;

// errors
const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';
const MSG_CONFLICT_BROKERAGE = 'Brokerage already exists.';

// action types
export const BROKERAGE_REQUEST = 'brokerage/BROKERAGE_REQUEST';
export const BROKERAGE_INFO = 'brokerage/BROKERAGE_INFO';
export const BROKERAGE_ADD_REQUEST = 'brokerage/BROKERAGE_ADD_REQUEST';

export const brokerageRequest = brokerageRequest => ({
  type: BROKERAGE_REQUEST,
  brokerageRequest,
});

export const brokerageAddRequest = brokerageAddRequest => ({
  type: BROKERAGE_ADD_REQUEST,
  brokerageAddRequest,
});

export const brokerageInfo = brokerageInfo => ({
  type: BROKERAGE_INFO,
  brokerageInfo,
});

export const loadBrokerage = () => async dispatch => {
  dispatch(brokerageRequest(true));
  dispatch(setRequestFeedback(''));
  const url = `${API_BASE}brokerages`;
  try {
    const res = await axios.get(url);
    dispatch(brokerageRequest(false));
    dispatch(brokerageInfo(res.data));
  } catch (err) {
    dispatch(setRequestFeedback(MSG_ERROR));
    dispatch(brokerageRequest(false));
  }
};

export const addBrokerage = values => async dispatch => {
  dispatch(brokerageAddRequest(true));
  const url = `${API_BASE}brokerages`;
  try {
    const res = await axios.post(url, values);
    if (res.status === HttpStatus.CREATED) {
      dispatch(brokerageAddRequest(false));
      await dispatch(loadBrokerage());
      dispatch(
        setRequestFeedback(`Successfully added brokerage [${values.name}].`),
      );
    }
  } catch ({ response }) {
    const status = response ? response.status : null;
    switch (status) {
      case HttpStatus.CONFLICT:
        dispatch(setRequestFeedback(MSG_CONFLICT_BROKERAGE));
        break;
      default:
        dispatch(setRequestFeedback(MSG_ERROR));
        break;
    }
    dispatch(brokerageAddRequest(false));
  }
};
