import axios from 'axios';
import { message } from 'antd';

import {
  LOCALSTORAGE_KEY_TOKEN, 
  LOCALSTORAGE_KEY_NAME, 
  LOCALSTORAGE_KEY_EXPIRY,
  LOCALSTORAGE_KEY_ADMIN_TOKEN
} from '../../../constants';

// action types
export const REQUEST_FEEDBACK = 'app/REQUEST_FEEDBACK';

export const setRequestFeedback = requestFeedback => ({
  type: REQUEST_FEEDBACK,
  requestFeedback
});

export const initialize = (expiry, history) => () => {
  const nowDate = new Date().getTime();
  if (nowDate <= expiry) {
    history.history.replace('/brokerages');
    const userToken = localStorage.getItem(LOCALSTORAGE_KEY_TOKEN);
    if (userToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    }
  } else {
    if (history.location.pathname !== '/') {
      message.error('Your account has been timed out, please log in again.', 3);
    }
    localStorage.removeItem(LOCALSTORAGE_KEY_TOKEN);
    localStorage.removeItem(LOCALSTORAGE_KEY_NAME);
    localStorage.removeItem(LOCALSTORAGE_KEY_EXPIRY);
    localStorage.removeItem(LOCALSTORAGE_KEY_ADMIN_TOKEN);
  }
};
