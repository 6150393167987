/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as HttpStatus from 'http-status-codes';
import {
  Row,
  Col,
  Breadcrumb,
  message,
  Tabs,
  Button,
  Input,
  Spin,
  Alert,
  Skeleton,
} from 'antd';
import {
  CloseCircleOutlined,
  CaretLeftOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import Layout from '../../components/layout/Layout';
import BrokerageInfo from './components/BrokerageInfo';
import BrokerAdminTable from './components/BrokerAdminTable';
import SubBrokerageTable from './components/SubBrokerageTable';
import TransactionTable from './components/TransactionTable';
import WrappedAddBrokerAdminModal from './components/AddBrokerAdminModal';
import WrappedAddBrokerageModal from '../brokerages/components/addBrokerageModal/AddBrokerageModal';
import withRouter from '../../components/common/hoc/withRouter';
import './detailedBrokerage.scss';

import { LOCALSTORAGE_KEY_NAME } from '../../constants';
import { loadBrokerage } from '../brokerages/BrokerageAction';

import {
  loadSingleBrokerage,
  loadBrokerageDetailedInfo,
  fetchBrokerageSubscriptions,
  changeQuota,
  deleteTableContent,
  brokerageDetailRequest,
  addBroker,
  addSubBrokerage,
  changeName,
  transferQuota,
  editTransaction,
} from './detailedBrokerageAction';

const { TabPane } = Tabs;

const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';
const MSG_CONFLICT_BROKER = 'Broker with this email already exists.';
const MSG_CONFLICT_BROKERAGE = 'Brokerage already exists, please set again.';
const MSG_NOT_FOUND = 'User not found.';
const MSG_NOT_ENOUGH_QUOTA_ASSIGN =
  'Sorry, there is no enough subscription key to assign.';
const MSG_NOT_ENOUGH_QUOTA_TRANSFER =
  'Sorry, there is no enough subscription key to transfer.';
const MSG_UPDATE_NAME = 'Successfully updated name.';
const MSG_NULL_NAME = 'Please enter a brokerage name.';

const adminUser = localStorage.getItem(LOCALSTORAGE_KEY_NAME);

class DetailedBrokerage extends Component {
  searchContentRef = React.createRef();

  state = {
    brokerAssigning: false,
    tab: 'brokers',
    searchContent: '',
    brokerageVisible: false,
    assignBrokerVisible: false,
    showNameSaveButton: false,
    visibleConfirmation: false,
    purchasePopover: false,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.id !== prevProps.params.id) {
      this.loadData();
    }
  }

  loadData = async () => {
    const { id } = this.props.params;
    await this.props.loadSingleBrokerage(id);
    await this.props.loadBrokerageDetailedInfo(id);
    await this.props.fetchBrokerageSubscriptions(id);
  };

  showSave = () => {
    this.setState({
      showNameSaveButton: true,
    });
  };

  handleEditTransaction = async (transactionId, amount) => {
    const data = { amount, initiator: adminUser };
    const { id } = this.props.params;
    const MSG_EDIT_TRANSACTION = `Successfully updated the amount`;
    try {
      await this.props.editTransaction(transactionId, data);
      message.success(MSG_EDIT_TRANSACTION, 2);
      await this.props.loadSingleBrokerage(id);
      await this.props.loadBrokerageDetailedInfo(id);
    } catch ({ response }) {
      message.error(MSG_ERROR, 2);
      await this.props.loadSingleBrokerage(id);
      await this.props.loadBrokerageDetailedInfo(id);
    }
  };

  handleSave = async (amount) => {
    const { id } = this.props.params;
    const adminUser = localStorage.getItem(LOCALSTORAGE_KEY_NAME);
    const data = { amount, initiator: adminUser };
    const MSG_UPDATE_QUOTA = `Successfully purchased ${amount} subscription key(s).`;
    try {
      await this.props.changeQuota(id, data);
      message.success(MSG_UPDATE_QUOTA, 3);
      await this.props.loadSingleBrokerage(id);
      await this.props.loadBrokerageDetailedInfo(id);
    } catch ({ response }) {
      message.error(MSG_ERROR, 2);
      await this.props.loadSingleBrokerage(id);
      await this.props.loadBrokerageDetailedInfo(id);
    }
    this.setState({ purchasePopover: false });
  };

  handleTransfer = async (toBrokerageId, amount, toBrokerageName) => {
    const fromBrokerage = this.props.params.id;
    const adminUser = localStorage.getItem(LOCALSTORAGE_KEY_NAME);
    const data = { amount, toBrokerageId, initiator: adminUser };
    const MSG_UPDATE_QUOTA = `Successfully transferred ${amount} subscription key(s) to ${toBrokerageName}.`;
    try {
      await this.props.transferQuota(fromBrokerage, data);
      message.success(MSG_UPDATE_QUOTA, 3);
      await this.props.loadSingleBrokerage(fromBrokerage);
      await this.props.loadBrokerageDetailedInfo(fromBrokerage);
    } catch ({ response }) {
      if (response.status === 402) {
        message.error(MSG_NOT_ENOUGH_QUOTA_TRANSFER, 2);
      } else {
        message.error(MSG_ERROR, 3);
      }
      await this.props.loadSingleBrokerage(fromBrokerage);
      await this.props.loadBrokerageDetailedInfo(fromBrokerage);
    }
  };

  handleNameSave = async (name) => {
    const { id } = this.props.params;
    if (name.name === '') {
      message.error(MSG_NULL_NAME);
    } else if (name.name !== this.props.singleBrokerageInfo.name) {
      try {
        await this.props.changeName(id, name);
        message.success(MSG_UPDATE_NAME, 2);
        await this.props.loadSingleBrokerage(id);
      } catch ({ response }) {
        if (response && response.status === HttpStatus.CONFLICT) {
          message.error(MSG_CONFLICT_BROKERAGE, 2);
          await this.props.loadSingleBrokerage(id);
        } else {
          message.error(MSG_ERROR, 2);
          await this.props.loadSingleBrokerage(id);
        }
      }
    }
    this.setState({
      showNameSaveButton: false,
    });
  };

  deleteTableContent = async (contentId, name) => {
    const { id } = this.props.params;
    const successMessage =
      this.state.tab === 'brokers'
        ? `Broker [${name}] has been removed.`
        : `Sub-brokerage [${name}] has been removed.`;
    try {
      await this.props.deleteTableContent(contentId, this.state.tab);
      this.props.setBrokerageDetailRequest(true);
      message.success(successMessage, 3);
      await this.props.loadBrokerageDetailedInfo(id);
      this.props.setBrokerageDetailRequest(false);
    } catch ({ response }) {
      message.error(MSG_ERROR, 2);
    }
  };

  deleteBrokerage = async (name) => {
    const { id } = this.props.params;
    const successMessage = `Brokerage [${name}] has been removed.`;

    const res = await this.props.deleteTableContent(id, 'brokerages');
    if (res.status === HttpStatus.NO_CONTENT) {
      this.props.navigate('/brokerages');
      message.success(successMessage, 3);
    } else {
      message.error(MSG_ERROR, 2);
    }
  };

  showModal = (visible) => {
    if (this.state.tab === 'subBrokerages') {
      this.setState({
        brokerageVisible: visible,
      });
    }
    if (this.state.tab === 'brokers') {
      this.setState({
        assignBrokerVisible: visible,
      });
    }
  };

  addBroker = async (values) => {
    this.setState({
      brokerAssigning: true,
    });
    const { id } = this.props.params;
    try {
      await this.props.addBroker(values, id);
      this.setState({
        brokerAssigning: false,
        assignBrokerVisible: false,
      });
      message.success(
        `Successfully assigned broker admin [${values.email}].`,
        3,
      );
      this.props.setBrokerageDetailRequest(true);
      await this.props.loadBrokerageDetailedInfo(id);
      this.props.setBrokerageDetailRequest(false);
    } catch ({ response }) {
      switch (response ? response.status : null) {
        case HttpStatus.CONFLICT:
          message.error(MSG_CONFLICT_BROKER, 3);
          break;
        case HttpStatus.NOT_FOUND:
          message.error(MSG_NOT_FOUND, 3);
          break;
        default:
          message.error(MSG_ERROR, 3);
          break;
      }
    }
    this.setState({
      brokerAssigning: false,
      assignBrokerVisible: false,
    });
  };

  addSubBrokerage = async (values) => {
    const { id } = this.props.params;
    const brokerageInfo = this.props.singleBrokerageInfo;
    const assigned = brokerageInfo?.subscriptions?.length;
    const changedQuota = brokerageInfo.quota - values.quota.transferred;
    this.setState({
      brokerAssigning: true,
    });
    if (changedQuota < assigned) {
      message.error(MSG_NOT_ENOUGH_QUOTA_ASSIGN, 3);
    } else {
      values.ParentBrokerageId = +id;
      try {
        await this.props.addSubBrokerage(values);
        message.success(
          `Successfully assigned sub-brokerage [${values.name}].`,
          3,
        );
        await this.props.loadBrokerage();
        this.setState({
          brokerAssigning: false,
        });
        this.props.setBrokerageDetailRequest(true);
        await this.props.loadBrokerageDetailedInfo(id);
        await this.props.loadSingleBrokerage(id);
        this.props.setBrokerageDetailRequest(false);
      } catch ({ response }) {
        switch (response ? response.status : null) {
          case HttpStatus.CONFLICT:
            message.error(MSG_CONFLICT_BROKERAGE, 3);
            break;
          default:
            message.error(MSG_ERROR, 3);
            break;
        }
      }
      this.setState({
        brokerAssigning: false,
      });
    }
  };

  changeTabPane = (activeKey) => {
    this.setState({
      tab: activeKey,
      searchContent: '',
    });
  };

  onSearchChange = (e) => {
    const query = e.target.value;
    this.setState({
      searchContent: query,
    });
  };

  filterBroker = (dataList, searchContent) => {
    if (searchContent === '') {
      return dataList;
    }
    const query = searchContent.replace(/\W|_/g, '[$&]');
    const regex = new RegExp(query, 'i');
    return dataList.filter(
      (obj) => obj?.firstName?.match(regex) || obj?.lastName?.match(regex),
    );
  };

  filterSubBrokerage = (dataList, searchContent) => {
    if (searchContent === '') {
      return dataList;
    }
    const query = searchContent.replace(/\W|_/g, '[$&]');
    const regex = new RegExp(query, 'i');
    return dataList.filter((obj) => obj.name.match(regex));
  };

  filterTransaction = (transactionList, searchContent) => {
    if (searchContent === '') {
      return transactionList;
    }
    const query = searchContent.replace(/\W|_/g, '[$&]');
    const regex = new RegExp(query, 'i');
    return (transactionList || []).filter(
      (obj) =>
        (obj.fromBrokerageName && obj.fromBrokerageName.match(regex)) ||
        (obj.toBrokerageName && obj.toBrokerageName.match(regex)) ||
        (obj.date && moment(obj.date).format('DD/MM/YYYY').match(regex)),
    );
  };

  emitEmpty = () => {
    this.searchContentRef.current.focus();
    this.setState({
      searchContent: '',
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ purchasePopover: visible });
  };

  render() {
    const {
      singleBrokerageInfo,
      brokerageDetailRequest,
      brokerageDetailedInfo,
      tableDataError,
    } = this.props;
    const tableData = brokerageDetailedInfo || {
      brokers: [],
      subBrokerages: [],
      transactions: [],
    };
    const { name, parentBrokerageId, parentBrokerageName, brokerageId } =
      singleBrokerageInfo;

    const {
      searchContent,
      brokerageVisible,
      assignBrokerVisible,
      brokerAssigning,
      showNameSaveButton,
      visibleConfirmation,
      tab,
      purchasePopover,
    } = this.state;
    const brokerData = this.filterBroker(tableData.brokers, searchContent);
    const subBrokerageData = this.filterSubBrokerage(
      tableData.subBrokerages,
      searchContent,
    );
    const transactionData = this.filterTransaction(
      tableData.transactions,
      searchContent,
    );
    const suffix = searchContent ? (
      <CloseCircleOutlined
        key="search-suffix"
        onClick={() => this.emitEmpty()}
      />
    ) : null;
    const prefix = <SearchOutlined key="search-prefix" type="search" />;

    return (
      <Layout>
        <Row type="flex" justify="center">
          <Col xs={22} sm={22} md={22} lg={20} xl={20} xxl={16}>
            <div className="breadCrumb-container">
              <Link to="/brokerages">
                <CaretLeftOutlined type="caret-left" />
              </Link>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/brokerages">BROKERAGES</Link>
                </Breadcrumb.Item>
                {parentBrokerageId && (
                  <Breadcrumb.Item>
                    <Link to={`/brokerages/${parentBrokerageId}`}>
                      {parentBrokerageName}
                    </Link>
                  </Breadcrumb.Item>
                )}
                <Breadcrumb.Item>{name}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {name ? (
              <BrokerageInfo
                brokerageInfo={singleBrokerageInfo}
                handleSave={this.handleSave}
                handleCountInput={this.handleCountInput}
                handleNameSave={this.handleNameSave}
                showNameSaveButton={showNameSaveButton}
                showSave={this.showSave}
                deleteBrokerage={this.deleteBrokerage}
                visibleConfirmation={visibleConfirmation}
                purchasePopover={purchasePopover}
                handleVisibleChange={this.handleVisibleChange}
              />
            ) : (
              <Skeleton active />
            )}
            <div className="tab-card">
              <Tabs
                onTabClick={(activeKey) => this.changeTabPane(activeKey)}
                tabBarExtraContent={
                  <Input
                    value={searchContent}
                    spellCheck={false}
                    onChange={this.onSearchChange}
                    size="large"
                    className="search-container-search"
                    placeholder="Search..."
                    ref={this.searchContentRef}
                    prefix={prefix}
                    suffix={suffix}
                  />
                }
              >
                <TabPane tab="Broker Admin" key="brokers">
                  {tableDataError !== '' && (
                    <Alert message={tableDataError} type="error" />
                  )}
                  {brokerageDetailRequest ? (
                    <div className="loading-spin-container">
                      <Spin tip="Loading..." />
                    </div>
                  ) : (
                    <BrokerAdminTable
                      data={brokerData}
                      deleteTableContent={this.deleteTableContent}
                      fetchBrokerageSubscriptions={
                        this.props.fetchBrokerageSubscriptions
                      }
                    />
                  )}
                </TabPane>
                <TabPane tab="Brokerages" key="subBrokerages">
                  {tableDataError !== '' && (
                    <Alert message={tableDataError} type="error" />
                  )}
                  {brokerageDetailRequest ? (
                    <div className="loading-spin-container">
                      <Spin tip="Loading..." />
                    </div>
                  ) : (
                    <SubBrokerageTable
                      data={subBrokerageData}
                      deleteTableContent={this.deleteTableContent}
                      handleTransfer={this.handleTransfer}
                    />
                  )}
                </TabPane>
                <TabPane tab="Transactions" key="transactions">
                  {tableDataError !== '' && (
                    <Alert message={tableDataError} type="error" />
                  )}
                  {brokerageDetailRequest ? (
                    <div className="loading-spin-container">
                      <Spin tip="Loading..." />
                    </div>
                  ) : (
                    <TransactionTable
                      data={transactionData}
                      brokerageId={brokerageId}
                      handleEditTransaction={this.handleEditTransaction}
                      handleVisibleChange={this.handleVisibleChange}
                      purchasePopover={purchasePopover}
                    />
                  )}
                </TabPane>
              </Tabs>
              {tab !== 'transactions' && (
                <div className="btn-container">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => this.showModal(true)}
                  >
                    Add new
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <WrappedAddBrokerAdminModal
            visible={assignBrokerVisible}
            showModal={this.showModal}
            addBroker={this.addBroker}
            brokerAssigning={brokerAssigning}
          />
          <WrappedAddBrokerageModal
            visible={brokerageVisible}
            showModal={this.showModal}
            addSubBrokerage={this.addSubBrokerage}
            brokerAssigning={brokerAssigning}
          />
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { appReducer, detailedBrokerageReducer } = state;
  return {
    ...detailedBrokerageReducer,
    requestFeedBack: appReducer.requestFeedBack,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadBrokerage: () => dispatch(loadBrokerage()),
  loadSingleBrokerage: (id) => dispatch(loadSingleBrokerage(id)),
  loadBrokerageDetailedInfo: (id) => dispatch(loadBrokerageDetailedInfo(id)),
  changeQuota: (id, data) => dispatch(changeQuota(id, data)),
  transferQuota: (id, data) => dispatch(transferQuota(id, data)),
  deleteTableContent: (id, tab) => dispatch(deleteTableContent(id, tab)),
  setBrokerageDetailRequest: (bool) => dispatch(brokerageDetailRequest(bool)),
  addBroker: (data, id) => dispatch(addBroker(data, id)),
  addSubBrokerage: (data) => dispatch(addSubBrokerage(data)),
  changeName: (id, data) => dispatch(changeName(id, data)),
  editTransaction: (id, data) => dispatch(editTransaction(id, data)),
  fetchBrokerageSubscriptions: (id) =>
    dispatch(fetchBrokerageSubscriptions(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DetailedBrokerage));
