import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import withRouter from '../common/hoc/withRouter';
import './header.scss';
import smallLogo from './images/lmi logo.png';

import {
  LOCALSTORAGE_KEY_TOKEN,
  LOCALSTORAGE_KEY_NAME,
  LOCALSTORAGE_KEY_EXPIRY,
} from '../../constants';

const Header = ({ location, navigate }) => {
  const [login, setLogin] = useState();

  useEffect(() => {
    setLogin(location.pathname.split('/')[1]);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem(LOCALSTORAGE_KEY_TOKEN);
    localStorage.removeItem(LOCALSTORAGE_KEY_NAME);
    localStorage.removeItem(LOCALSTORAGE_KEY_EXPIRY);
    navigate('/');
  };

  return (
    <Layout.Header className="header">
      <div className="header-container">
        <Link to="/brokerages">
          <div className="header-container header-brand">
            <img src={smallLogo} alt="lmi-logo" style={{ height: '49px' }} />
            <div className="lmi-continuity">
              LMI Continuity
              <span className="coach-admin">Coach Admin</span>
            </div>
          </div>
        </Link>
        <div
          style={{ display: !login && 'none' }}
          className="logout-icon-container"
          onClick={handleLogout}
        >
          <LogoutOutlined className="logout-icon" />
          <div style={{ lineHeight: '27px' }}>Log out</div>
        </div>
      </div>
    </Layout.Header>
  );
};

export default withRouter(Header);
