import React from 'react';
import ReactEcharts from 'echarts-for-react';
import './ProgressChart.scss';

const COLOR_SUBSCRIPTION = '#f1c400';
const COLOR_PROMOCODES = '#f7d12d';
const COLOR_SENT = '#f5de80';
const COLOR_REVOKED = '#efe7b4';
const COLOR_REMAINING = '#ddd';
const COLOR_TEXT = 'rgba(0, 0, 0, 0.55)';
const COLOR_LABEL = 'rgba(0,0,0,.75)';

export default ({ quota }) => {
  const {
    total,
    remaining,
    expiredSubscriptions,
    promoCodes,
    subscriptions,
    sent,
    revoked,
  } = quota || {
    total: 0,
    remaining: 0,
    expiredSubscriptions: 0,
    promoCodes: 0,
    subscriptions: 0,
    sent: 0,
    revoked: 0,
  };

  const data = [
    {
      name: 'Subscriptions Keys',
      value: subscriptions >= 0 ? subscriptions : 0,
      color: COLOR_SUBSCRIPTION,
    },
    {
      name: 'PromoCodes Keys',
      value: promoCodes >= 0 ? promoCodes : 0,
      color: COLOR_PROMOCODES,
    },
    { name: 'Sent Keys', value: sent >= 0 ? sent : 0, color: COLOR_SENT },
    {
      name: 'Revoked Keys',
      value: revoked >= 0 ? revoked : 0,
      color: COLOR_REVOKED,
    },
    {
      name: 'Remaining Keys',
      value: remaining >= 0 ? remaining : 0,
      color: COLOR_REMAINING,
    },
  ];

  const option = {
    title: {
      text: [
        `{total|${total}}`,
        `{expired|${expiredSubscriptions} expired}`,
      ].join(`\n`),
      x: 'center',
      y: 'center',
      textStyle: {
        rich: {
          total: {
            fontSize: 24,
            lineHeight: 24,
            fontWeight: 'normal',
            color: COLOR_TEXT,
          },
          expired: {
            fontSize: 12,
            lineHeight: 20,
            fontWeight: 'normal',
            color: COLOR_TEXT,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['35%', '50%'],
        label: {
          color: COLOR_LABEL,
          fontSize: 12,
          lineHeight: 14,
          formatter: ({ data }) =>
            data.value === 0 ? '' : `${data.name}: ${data.value}`,
        },
        labelLine: { show: false, length: 4, length2: 4 },
        itemStyle: { color: ({ data }) => data.color },
        data,
        left: 0,
        top: '-30%',
        right: 0,
        bottom: '-24%',
      },
    ],
  };

  return (
    <div className="progress-chart">
      <ReactEcharts
        option={option}
        notMerge
        lazyUpdate
        style={{ height: '100%' }}
      />
    </div>
  );
};
