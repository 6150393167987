/* eslint-disable consistent-return */
import axios from 'axios';
import * as HttpStatus from 'http-status-codes';
import {
  LOCALSTORAGE_KEY_TOKEN,
  LOCALSTORAGE_KEY_NAME,
  LOCALSTORAGE_KEY_ADMIN_TOKEN,
} from '../../constants';

// APIs
const API_BASE = process.env.REACT_APP_API_BASE;
const API_LMI_ADMIN = process.env.REACT_APP_API_LMI_ADMIN;
const LOGIN_ACCOUNT = process.env.REACT_APP_CC_LOGIN_ACCOUNT;
const LOGIN_PASSWORD = process.env.REACT_APP_CC_LOGIN_PASSWORD;
const API_AUTH_TOKEN = 'auth/token';

// errors
const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';
const MSG_LOGIN_RESTRICT =
  'You do not have administrator privileges to access this site.';
const MSG_LOGIN_FAIL = 'The username or password is incorrect.';

// action types
export const LOGIN_REQUEST = 'login/LOGIN_REQUEST';
export const ERROR_MESSAGE = 'login/ERROR_MESSAGE';
export const EXPIRY_DATE = 'login/EXPIRY_DATE';

export const loginRequest = (loginRequest) => ({
  type: LOGIN_REQUEST,
  loginRequest,
});

export const setErrorMessage = (errorMessage) => ({
  type: ERROR_MESSAGE,
  errorMessage,
});

export const setExpiryDate = (expiryDate) => ({
  type: EXPIRY_DATE,
  expiryDate,
});

const loginCC = (username) => async (dispatch) => {
  const urlCC = `${API_BASE}${API_AUTH_TOKEN}`;
  const data = { email: LOGIN_ACCOUNT, password: LOGIN_PASSWORD };
  try {
    const resCC = await axios.post(urlCC, data);
    const userToken = resCC.data.access_token;
    axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    localStorage.setItem(LOCALSTORAGE_KEY_TOKEN, userToken);
    localStorage.setItem(LOCALSTORAGE_KEY_NAME, username);
    dispatch(loginRequest(false));
    return Promise.resolve(resCC);
  } catch ({ response }) {
    const status = response ? response.status : null;
    switch (status) {
      case HttpStatus.UNAUTHORIZED:
        dispatch(setErrorMessage(MSG_LOGIN_RESTRICT));
        break;
      default:
        dispatch(setErrorMessage(MSG_ERROR));
        break;
    }
    dispatch(loginRequest(false));
  }
  dispatch(loginRequest(false));
};

export const login = (values) => {
  const { username, password } = values;
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  params.append('grant_type', 'password');
  params.append('client_id', 10);
  return async (dispatch) => {
    dispatch(loginRequest(true));
    dispatch(setErrorMessage(''));
    const urlLmiAdmin = `${API_LMI_ADMIN}`;
    try {
      const resAdmin = await axios.post(urlLmiAdmin, params.toString());
      const adminToken = resAdmin.data.access_token;
      localStorage.setItem(LOCALSTORAGE_KEY_ADMIN_TOKEN, adminToken);
      const res = await dispatch(loginCC(username));
      return res;
    } catch ({ response }) {
      const status = response ? response.status : null;

      switch (status) {
        case HttpStatus.BAD_REQUEST:
          dispatch(setErrorMessage(MSG_LOGIN_FAIL));
          break;
        default:
          dispatch(setErrorMessage(MSG_ERROR));
          break;
      }
      dispatch(loginRequest(false));
    }
    dispatch(loginRequest(false));
  };
};
