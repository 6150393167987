/* eslint-disable default-param-last */
import {
  BROKERAGE_REQUEST,
  BROKERAGE_ADD_REQUEST,
  BROKERAGE_INFO,
} from './BrokerageAction';

import {
  REQUEST_BROKERAGE_SUBSCRIPTIONS,
  RECEIVE_BROKERAGE_SUBSCRIPTIONS,
} from '../detailedBrokerage/detailedBrokerageAction';

const initialState = {
  brokerageRequest: false,
  brokerageAddRequest: false,
  brokerageInfo: '',
  loadingBrokerageSubscriptions: false,
};

export const brokerageReducer = (state = initialState, action) => {
  switch (action.type) {
    case BROKERAGE_REQUEST:
      return { ...state, brokerageRequest: action.brokerageRequest };
    case BROKERAGE_INFO:
      return { ...state, brokerageInfo: action.brokerageInfo };
    case BROKERAGE_ADD_REQUEST:
      return { ...state, brokerageAddRequest: action.brokerageAddRequest };
    case REQUEST_BROKERAGE_SUBSCRIPTIONS:
      return { ...state, loadingBrokerageSubscriptions: true };
    case RECEIVE_BROKERAGE_SUBSCRIPTIONS: {
      if (state.brokerageInfo) {
        const brokerageInfo = [];
        state.brokerageInfo.forEach((brokerage) => {
          if (action.brokerageId === brokerage.brokerageId) {
            brokerageInfo.push({
              ...brokerage,
              summary: action.brokerageSubscriptions.summary,
            });
          } else {
            brokerageInfo.push({
              ...brokerage,
            });
          }
        });
        return {
          ...state,
          loadingBrokerageSubscriptions: false,
          brokerageInfo,
        };
      }
      return {
        ...state,
        loadingBrokerageSubscriptions: false,
      };
    }
    default:
      return state;
  }
};
