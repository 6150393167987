import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Input, Checkbox, Alert } from 'antd';
import { Form } from '@ant-design/compatible';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Layout from '../../components/layout/Layout';
import withRouter from '../../components/common/hoc/withRouter';
import { LOCALSTORAGE_KEY_EXPIRY } from '../../constants';
import { login, setExpiryDate } from './LoginAction';
import './login.scss';
import userLogin from './images/users_lmi.png';

class Login extends Component {
  COLOR_GREY = 'rgba(0,0,0,.25)';

  redirect = (rememberMe) => {
    this.props.navigate('/brokerages');
    const date = new Date().getTime();
    let expiryDate = date + 60 * 60 * 24 * 1 * 1000;
    if (rememberMe) {
      expiryDate = date + 60 * 60 * 24 * 7 * 1000;
    }
    localStorage.setItem(LOCALSTORAGE_KEY_EXPIRY, expiryDate);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.login(values).then(() => this.redirect(values.rememberme));
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loginRequest, errorMessage } = this.props;
    return (
      <Layout>
        <Row>
          <Col xs={20} sm={20} md={12} lg={12} xl={12} xxl={12}>
            <div className="login-left">
              <div className="image-container">
                <img src={userLogin} alt="user-login" />
                <div className="login-to-you-account">
                  LOGIN TO YOUR ACCOUNT
                </div>
              </div>
            </div>
          </Col>
          <Col xs={20} sm={20} md={12} lg={12} xl={12} xxl={12}>
            <Row
              className="login-right"
              type="flex"
              justify="center"
              align="middle"
            >
              <Col xs={22} sm={22} md={20} lg={18} xl={16} xxl={12}>
                {errorMessage !== '' && (
                  <Alert message={errorMessage} type="error" />
                )}
                <Form className="login-form-container" onSubmit={this.onSubmit}>
                  <Form.Item label="LMI Username">
                    {getFieldDecorator('username', {
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          required: true,
                          message: 'Please input your username.',
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <UserOutlined style={{ color: this.COLOR_GREY }} />
                        }
                        spellCheck={false}
                        placeholder="Username"
                        disabled={loginRequest}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label="Password">
                    {getFieldDecorator('password', {
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          required: true,
                          message: 'Please input your password.',
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <LockOutlined style={{ color: this.COLOR_GREY }} />
                        }
                        placeholder="Password"
                        type="password"
                        disabled={loginRequest}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('rememberme', {
                      valuePropName: 'checked',
                      initialValue: false,
                    })(<Checkbox>Remember me</Checkbox>)}
                  </Form.Item>
                  <Form.Item className="sign-in-button">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loginRequest}
                    >
                      {loginRequest ? 'Signing in' : 'Sign in'}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { loginReducer } = state;
  return {
    ...loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(login(values)),
  setExpiryDate: (date) => dispatch(setExpiryDate(date)),
});

const WrappedLogin = Form.create()(Login);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(WrappedLogin));
