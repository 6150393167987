/* eslint-disable default-param-last */
import { REQUEST_FEEDBACK } from './AppAction';

const initialState = {
  requestFeedback: '',
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FEEDBACK:
      return { ...state, requestFeedback: action.requestFeedback };
    default:
      return state;
  }
};
