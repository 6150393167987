import React from 'react';
import { Layout as AntLayout } from 'antd';
import withRouter from '../common/hoc/withRouter';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import './layout.scss';

const { Content } = AntLayout;

const Layout = ({ history, location, children }) => (
  <AntLayout className="layout">
    <Header history={history} location={location} className="header" />
    <Content>{children}</Content>
    <Footer />
  </AntLayout>
);

export default withRouter(Layout);
