import React, { Component } from 'react';
import { Collapse } from 'antd';
import BrokerageCard from './BrokerageCard';

const { Panel } = Collapse;

export default class RecursiveCollapsedCard extends Component {
  render() {
    const { showContent = [], allBrokerage } = this.props;
    return (
      <Collapse>
        {showContent.map(brokerage => {
          const showContent = allBrokerage[brokerage.brokerageId] || [];
          const subBrokerageCount = showContent.length;
          return (
            <Panel
              key={brokerage.brokerageId}
              showArrow={subBrokerageCount !== 0}
              disabled={subBrokerageCount === 0}
              header={
                <BrokerageCard brokerage={brokerage} subBrokerage={subBrokerageCount} key={brokerage.brokerageId} />
              }
            >
              {subBrokerageCount !== 0 && (
                <RecursiveCollapsedCard allBrokerage={allBrokerage} showContent={showContent} />
              )}
            </Panel>
          );
        })}
      </Collapse>
    );
  }
}
