import React, { Component } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { MinusOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import ControlledPopover from './ControlledPopover';

const TRANSACTION_TYPE = Object.freeze({
  PURCHASE: 'PURCHASE',
  TRANSFER: 'TRANSFER',
});
const COLOR_RED = '#cb2c30';
const COLOR_GREEN = '#00685e';

export default class Transaction extends Component {
  getAmount = (record) => {
    const { brokerageId } = this.props;
    if (
      record.type === TRANSACTION_TYPE.TRANSFER &&
      brokerageId === record.fromBrokerageId
    ) {
      return -record.amount;
    }
    return record.amount;
  };

  render() {
    const offset = new Date().getTimezoneOffset();
    const { data, brokerageId, handleEditTransaction } = this.props;
    const columns = [
      {
        key: 'type',
        title: 'Type',
        dataIndex: 'type',
        width: '15%',
        render: (text, record) =>
          record.type === TRANSACTION_TYPE.TRANSFER &&
          brokerageId === record.fromBrokerageId ? (
            <div>
              <MinusOutlined
                style={{ color: COLOR_RED, marginRight: '10px' }}
              />
              {record.type}
            </div>
          ) : (
            <div>
              <PlusOutlined
                style={{ color: COLOR_GREEN, marginRight: '10px' }}
              />
              {record.type}
            </div>
          ),
        filters: [
          { text: TRANSACTION_TYPE.TRANSFER, value: TRANSACTION_TYPE.TRANSFER },
          { text: TRANSACTION_TYPE.PURCHASE, value: TRANSACTION_TYPE.PURCHASE },
        ],
        onFilter: (value, record) => record.type.indexOf(value) === 0,
      },
      {
        key: 'date',
        title: 'Date',
        width: '16%',
        render: (text, record) => (
          <span>
            {record.date
              ? moment
                  .utc(record.date)
                  .utcOffset(-offset)
                  .format('YYYY-MM-DD HH:mm')
              : null}
          </span>
        ),
        sorter: (a, b) =>
          new Date(a.date).getTime() - new Date(b.date).getTime(),
      },
      {
        key: 'from',
        title: 'Transferred From',
        dataIndex: 'fromBrokerageName',
        width: '20%',
        render: (text, record) => (
          <span>
            {record.fromBrokerageName ? record.fromBrokerageName : '-'}
          </span>
        ),
      },
      {
        key: 'to',
        title: 'Transferred To',
        dataIndex: 'toBrokerageName',
        width: '20%',
        render: (text, record) => (
          <span>{record.toBrokerageName ? record.fromBrokerageName : '-'}</span>
        ),
      },
      {
        key: 'createBy',
        title: 'Created by',
        dataIndex: 'initiator',
        width: '20%',
      },
      {
        key: 'amount',
        title: 'Amount',
        align: 'right',
        width: '10%',
        render: (text, record) => (
          <div
            style={{
              color: this.getAmount(record) < 0 ? COLOR_RED : COLOR_GREEN,
            }}
          >
            <span style={{ marginRight: '22px' }}>
              {' '}
              {this.getAmount(record)}
            </span>
            {record.fromBrokerageId === brokerageId ||
            !record.fromBrokerageId ? (
              <ControlledPopover
                placement="top"
                trigger="click"
                record={record}
                handleEditTransaction={handleEditTransaction}
              />
            ) : (
              <EditOutlined type="edit" style={{ color: 'transparent' }} />
            )}
          </div>
        ),
      },
    ];
    return (
      <Table
        loading={!data}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.transactionId}
        pagination={data.length > 10}
        locale={{ emptyText: 'No Transactions' }}
      />
    );
  }
}
