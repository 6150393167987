import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ControlledPopover from './ControlledPopover';

const colorBlack = 'rgba(0, 0, 0, 0.45)';
const primaryColor = '#f1c400';

export default class SubBrokerageTable extends Component {
  render() {
    const { data, deleteTableContent, handleTransfer } = this.props;
    const columns = [
      {
        title: 'Name',
        key: 'name',
        width: '30%',
        render: (record) => (
          <Link to={`/brokerages/${record.brokerageId}`}>
            <p className="table-brokerage-name">{record.name}</p>
          </Link>
        ),
      },
      {
        title: (
          <span>
            Subscription Key(s)
            <br />
            <span style={{ color: colorBlack }}>Transferred & Purchased</span>
          </span>
        ),
        dataIndex: 'quota.total',
        key: 'quota',
        width: '30%',
      },
      {
        title: 'Created by',
        dataIndex: 'createdBy',
        key: 'create',
        width: '30%',
      },
      {
        key: 'action',
        width: 90,
        render: (text, record) => (
          <div className="last-column">
            <ControlledPopover
              transfer
              placement="top"
              trigger="click"
              record={record}
              handleTransfer={handleTransfer}
            />
            <Divider type="vertical" style={{ color: primaryColor }} />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() =>
                deleteTableContent(record.brokerageId, record.name)
              }
            >
              <DeleteOutlined />
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <div className="broker-admin-table">
        <Table
          columns={columns}
          dataSource={data || []}
          rowKey={(item) => item.brokerageId}
          pagination={(data || []).length > 10 && { pageSize: 10 }}
          locale={{ emptyText: 'No sub-brokerage' }}
        />
      </div>
    );
  }
}
