/* eslint-disable default-param-last */
import {
  SINGLE_BROKERAGE_INFO,
  BROKERAGE_DETAILED_REQUEST,
  BROKERAGE_DETAILED_INFO,
  SET_TABLE_DATA_ERROR,
  REQUEST_BROKERAGE_SUBSCRIPTIONS,
  RECEIVE_BROKERAGE_SUBSCRIPTIONS,
} from './detailedBrokerageAction';

const initialState = {
  singleBrokerageInfo: {},
  brokerageDetailRequest: false,
  brokerageDetailedInfo: { brokers: [], subBrokerages: [], transactions: [] },
  tableDataError: '',
  loadingBrokerageSubscriptions: false,
};

export const detailedBrokerageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_BROKERAGE_INFO: {
      return {
        ...state,
        singleBrokerageInfo: {
          ...action.singleBrokerageInfo,
        },
      };
    }
    case BROKERAGE_DETAILED_REQUEST:
      return {
        ...state,
        brokerageDetailRequest: action.brokerageDetailRequest,
      };
    case BROKERAGE_DETAILED_INFO:
      return { ...state, brokerageDetailedInfo: action.brokerageDetailedInfo };
    case SET_TABLE_DATA_ERROR:
      return { ...state, tableDataError: action.tableDataError };
    case REQUEST_BROKERAGE_SUBSCRIPTIONS:
      return { ...state, loadingBrokerageSubscriptions: true };
    case RECEIVE_BROKERAGE_SUBSCRIPTIONS: {
      if (state.brokerageDetailedInfo.brokers) {
        const brokers = [];
        state.brokerageDetailedInfo.brokers.forEach((broker) => {
          if (String(action.brokerageId) === String(broker.brokerageId)) {
            brokers.push({
              ...broker,
              summary: action.brokerageSubscriptions.summary,
            });
          } else {
            brokers.push({
              ...broker,
            });
          }
        });
        return {
          ...state,
          loadingBrokerageSubscriptions: false,
          brokerageDetailedInfo: { ...state.brokerageDetailedInfo, brokers },
          singleBrokerageInfo: {
            ...state.singleBrokerageInfo,
            quota: action.brokerageSubscriptions.summary,
            subscriptions: action.brokerageSubscriptions.subscriptions,
          },
        };
      }
      return {
        ...state,
        loadingBrokerageSubscriptions: false,
      };
    }

    default:
      return state;
  }
};
