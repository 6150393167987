import React from 'react';
import { Layout } from 'antd';
import './footer.scss';
import smallLogo from '../header/images/lmi logo.png';

const { Footer } = Layout;

export default () => (
  <Footer className="footer">
    <div className="footer-copyright">
      &copy; 1999 - {new Date().getFullYear()} <strong>LMI Group Pty Ltd</strong>. All Rights Reserved.
    </div>
    <a className="footer-cc" href="https://continuitycoach.com/" target="_blank" rel="noopener noreferrer">
      <img src={smallLogo} alt="lmi-logo" style={{ height: '40px' }} />
      <div>
        LMI Continuity
        <span className="coach">Coach</span>
      </div>
    </a>
  </Footer>
);
