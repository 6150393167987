import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { ConfigProvider } from 'antd';
import App from './components/common/app/App';
import Login from './scenes/login/Login';
import Brokerages from './scenes/brokerages/Brokerages';
import DetailedBrokerage from './scenes/detailedBrokerage/DetailedBrokerage';

import { brokerageReducer } from './scenes/brokerages/BrokerageReducer';
import { loginReducer } from './scenes/login/LoginReducer';
import { appReducer } from './components/common/app/AppReducer';
import { detailedBrokerageReducer } from './scenes/detailedBrokerage/detailedBrokerageReducer';

import 'antd/dist/reset.css';
import './index.scss';

const themeConfig = {
  token: {
    colorPrimary: '#f1c400',
    borderRadius: 0,
    colorBgLayout: '#ffffff',
    fontFamily:
      "Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif",
  },
};

const rootReducer = combineReducers({
  brokerageReducer,
  loginReducer,
  appReducer,
  detailedBrokerageReducer,
});

const middlewares = [];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(thunk, ...middlewares));

const PrivateRoute = ({ component: RouteComponent, props }) => {
  if (!localStorage.getItem('ccadmin.token')) {
    return <Navigate to="/" />;
  }

  return <RouteComponent {...props} />;
};

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ConfigProvider theme={themeConfig}>
      <Router>
        <Routes>
          <Route element={<App />} />
          <Route exact path="/" element={<Login />} />
          <Route
            exact
            path="/brokerages"
            element={<PrivateRoute component={Brokerages} />}
          />
          <Route
            exact
            path="/brokerages/:id"
            element={<PrivateRoute component={DetailedBrokerage} />}
          />
        </Routes>
      </Router>
    </ConfigProvider>
  </Provider>,
);
