/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { Modal, Button, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import { LOCALSTORAGE_KEY_NAME } from '../../../constants';

class AddBrokerAdminModal extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const adminUser = localStorage.getItem(LOCALSTORAGE_KEY_NAME);
        values.createBy = adminUser;
        this.props.addBroker(values);
        this.props.form.resetFields();
      }
    });
  };

  render() {
    const { visible, showModal, brokerAssigning } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        closable
        maskClosable
        title="Assign broker admin"
        open={visible}
        footer={null}
        onCancel={() => showModal(false)}
        className="add-brokerage-modal"
      >
        <Form layout="vertical" onSubmit={this.onSubmit}>
          <Form.Item label="Email" colon={false}>
            {getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: "Please input broker's email.",
                },
              ],
            })(<Input size="large" placeholder="Required Field" />)}
          </Form.Item>
          <Form.Item className="add-button-container">
            <Button type="primary" htmlType="submit">
              {brokerAssigning ? 'Assigning' : 'Assign'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedAddBrokerAdminModal = Form.create()(AddBrokerAdminModal);
export default WrappedAddBrokerAdminModal;
