import React, { Component } from 'react';
import { Table, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export default class BrokerAdminTable extends Component {
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { data } = this.props;
    data.forEach(async (broker) => {
      try {
        await this.props.fetchBrokerageSubscriptions(broker.brokerageId);
      } catch (err) {
        message.error(err);
      }
    });
  };

  render() {
    const { data, deleteTableContent } = this.props;
    const columns = [
      {
        title: 'Name',
        key: 'name',
        width: '18%',
        render: (text, value) => (
          <span>{`${value.firstName} ${value.lastName}`}</span>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '28%',
      },
      {
        title: 'Subscription key(s) assigned',
        key: 'licenses',
        width: '22%',
        render: (text, value) => (
          <span>{value.summary ? `${value.summary.subscriptions}` : 0}</span>
        ),
      },
      {
        title: 'Created by',
        dataIndex: 'createdBy',
        key: 'create',
        width: '25%',
      },
      {
        key: 'action',
        width: 90,
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() =>
                deleteTableContent(
                  record.brokerId,
                  `${record.firstName} ${record.lastName}`,
                )
              }
            >
              <DeleteOutlined />
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <div className="broker-admin-table">
        <Table
          columns={columns}
          dataSource={data || []}
          rowKey={(item) => item.brokerId}
          pagination={(data || []).length > 10 && { pageSize: 10 }}
          locale={{ emptyText: 'No Broker Admin' }}
        />
      </div>
    );
  }
}
